import React from "react";

import "./LandingPage.scss";

const TopFold = React.lazy(() =>  import("../../Components/LandingPageComponents/TopFold"));
const Services = React.lazy(() =>  import("../../Components/LandingPageComponents/Services"));
const Clients = React.lazy(()   => import  ("../../Components/LandingPageComponents/Clients"));
const ContactUs = React.lazy(()   => import  ("../../Components/LandingPageComponents/ContactUs")) 
// const FooterComponent = React.lazy(() =>  import("../../Components/FooterComponent"));

const LandingPage = () => {
  return (
    <div className="landing-page">
      <TopFold/>
      <Services/>
      <Clients/>
      <ContactUs/>
      {/* <FooterComponent {...props} /> */}
    </div>
  );
};

export default LandingPage;
