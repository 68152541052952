import React, { useEffect } from "react";
import '../src/App.css';
import LandingPage from './Page/LandingPage/LandingPage';
const NavBarComponent = React.lazy(() =>  import("./Components/NavBarComponent"));



function App() {
  useEffect(()=> {console.log("asdas")},[])
  return (
    <div className="App">
      <NavBarComponent/>
      <LandingPage/>
    </div>
  );
}

export default App;
